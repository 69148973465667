/** The models in this file is only used for the purchase screen */

import { Expose, Type } from 'class-transformer';
import {
  DataPlanTypeEnum,
  ProductItemGradeEnum,
  ProductScaleEnum,
} from 'common/constants/enum';
import { string2money } from 'common/utils/string';
import { Translate } from 'next-translate';

import { ProductLiteModel } from './base';
import { ProductCoverageModel } from './network';

export class ProductBrowseResponse {
  @Type(() => ProductLiteModel)
  regionals: ProductLiteModel[];

  @Type(() => ProductLiteModel)
  populars: ProductLiteModel[];

  @Type(() => ProductLiteModel)
  countries: ProductLiteModel[];
}

export class ProcessedProductItemPriceModel {
  id: string;

  @Expose({ name: 'currency_code' })
  currencyCode: string;

  @Type(() => Number)
  price: number;

  @Expose({ name: 'discounted_price' })
  @Type(() => Number)
  discountedPrice: number;

  @Expose({ name: 'discount_percentage' })
  @Type(() => Number)
  discountPercentage: number | null;

  static format(selectedPrice: ProcessedProductItemPriceModel) {
    return `${selectedPrice.currencyCode} ${string2money(selectedPrice.discountedPrice)}`;
  }
}

export class ProcessedProductItemModel {
  /** Used for sorting */
  @Expose({ name: 'natural_name' })
  naturalName: string;

  @Expose({ name: 'is_recommended' })
  isRecommended: boolean;

  @Expose({ name: 'validity_days' })
  @Type(() => Number)
  validityDays: number;

  @Expose({ name: 'data_type' })
  dataType: DataPlanTypeEnum;

  @Expose({ name: 'quota_in_gb' })
  @Type(() => Number)
  quotaInGb: number;

  @Type(() => ProcessedProductItemPriceModel)
  standard: ProcessedProductItemPriceModel;

  @Type(() => ProcessedProductItemPriceModel)
  premium: ProcessedProductItemPriceModel | null;

  static getPrice(
    item: ProcessedProductItemModel,
    grade: ProductItemGradeEnum,
  ): ProcessedProductItemPriceModel {
    if (grade === ProductItemGradeEnum.Premium && item.premium) {
      return item.premium;
    } else {
      return item.standard;
    }
  }

  static hasPremium(item: ProcessedProductItemModel) {
    return item.premium != null && item.premium.id != null;
  }

  static findById(
    items: ProcessedProductItemModel[],
    id: string,
  ): [ProcessedProductItemModel, ProductItemGradeEnum] | undefined {
    const plan = items.find(
      (item) =>
        item.standard.id === id || (item.premium && item.premium.id === id),
    );
    if (!plan) return undefined;
    return [
      plan,
      plan?.premium?.id === id
        ? ProductItemGradeEnum.Premium
        : ProductItemGradeEnum.Standard,
    ];
  }

  static sort(array: ProcessedProductItemModel[]): ProcessedProductItemModel[] {
    return array.sort((a, b) =>
      a.naturalName === b.naturalName
        ? 0
        : a.naturalName < b.naturalName
          ? -1
          : 1,
    );
  }

  static label(item: ProcessedProductItemModel, t: Translate) {
    return `${item.validityDays} ${t('common:day', { count: item.validityDays })} | ${item.dataType === DataPlanTypeEnum.Unlimited ? t('enum:data_plan_type:unlimited') : `${string2money(item.quotaInGb)} GB`}`;
  }
}

class ProcessedProductModelCoverages {
  @Type(() => ProductCoverageModel)
  standard: ProductCoverageModel[];

  // Becomes empty array if no premium
  @Type(() => ProductCoverageModel)
  premium: ProductCoverageModel[];
}

export class ProcessedProductModel {
  id: string;
  name: string;
  slug: string;
  description: string;
  scale: ProductScaleEnum;

  @Expose({ name: 'is_saleable' })
  isSaleable: boolean;

  @Expose({ name: 'area_color' })
  areaColor: string;

  @Expose({ name: 'area_code' })
  areaCode: string;

  @Expose({ name: 'total_countries' })
  @Type(() => Number)
  totalCountries: number;

  details: {
    text: string;
    value: boolean;
  }[];

  @Expose({ name: 'product_items' })
  @Type(() => ProcessedProductItemModel)
  productItems: ProcessedProductItemModel[];

  @Type(() => ProcessedProductModelCoverages)
  coverages: ProcessedProductModelCoverages;
}

export type getProductDetailInput = {
  slug: string;
};
