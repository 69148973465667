import { getParamsInput } from 'api-hooks/common';
import { Expose } from 'class-transformer';
import { ProductScaleEnum } from 'common/constants/enum';

export interface AreaModel {
  id: string;
  name: string;
  scale: ProductScaleEnum;
  areaCode: string;
  areaColor?: string | null;
  totalCountries: number;
  slug: string;
}

// Note: There's no ProductModel; but convention dictates that the lite models are for index.
export class ProductLiteModel {
  id: string;
  name: string;
  slug: string;
  description: string;
  scale: ProductScaleEnum;

  @Expose({ name: 'is_saleable' })
  isSaleable: boolean;

  @Expose({ name: 'area_color' })
  areaColor: string;

  @Expose({ name: 'area_code' })
  areaCode: string;

  @Expose({ name: 'total_countries' })
  totalCountries: number;
}

export type BrowseProductsInput = getParamsInput<{
  filter?: {
    simId?: string;
  };
}>;
