import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from '@tanstack/react-query';
import { getParamsInput, StaleTimes } from 'api-hooks/common';
import { QueryFetchFunction, QueryTransformer } from 'common/api/fetch';
import { useHttpCacheToken } from 'common/api/http-cache-token';
import { ApiError, ApiResult } from 'common/api/model';

import {
  BrowseProductsInput,
  ProductBrowseResponse,
  getProductDetailInput,
  ProcessedProductModel,
} from './model';

export const productsKey = {
  browseKey: 'browseProducts',
  detailKey: 'getProduct',
  coverageKey: 'getProductCoverage',
  detail(input: getProductDetailInput, token: string) {
    return [productsKey.detailKey, input.slug, token];
  },
  browse(input: getParamsInput | undefined, token: string) {
    return [productsKey.browseKey, input, token];
  },
};

const ENDPOINT = 'products';

export function useBrowseProducts(
  input?: BrowseProductsInput,
  options?: UseQueryOptions<ApiResult<ProductBrowseResponse>, ApiError>,
): UseQueryResult<ApiResult<ProductBrowseResponse>, ApiError> {
  const { apply, token } = useHttpCacheToken();
  return QueryTransformer(
    useQuery<ApiResult<ProductBrowseResponse>, ApiError>(
      productsKey.browse(input, token),
      () =>
        QueryFetchFunction({
          url: ENDPOINT,
          params: input?.params ? input.params : apply(undefined),
        }),
      {
        staleTime: input == null ? StaleTimes.Long : StaleTimes.Short,
        cacheTime: input == null ? Infinity : undefined,
        ...options,
      },
    ),
    ProductBrowseResponse,
  );
}

export function useGetProduct(
  input: getProductDetailInput,
  options?: UseQueryOptions<ApiResult<ProcessedProductModel>, ApiError>,
): UseQueryResult<ApiResult<ProcessedProductModel>, ApiError> {
  const { apply, token } = useHttpCacheToken();
  return QueryTransformer(
    useQuery<ApiResult<ProcessedProductModel>, ApiError>(
      productsKey.detail(input, token),
      () =>
        QueryFetchFunction({
          url: `${ENDPOINT}/${input.slug}`,
          params: apply(undefined),
        }),
      {
        staleTime: StaleTimes.Long,
        cacheTime: StaleTimes.Long * 2,
        ...options,
      },
    ),
    ProcessedProductModel,
  );
}
