import { Expose, Type } from 'class-transformer';

export class ProductCoverageNetworkModel {
  id: string;
  name: string;

  @Expose({ name: 'support_4g_lte' })
  support4gLte: boolean;

  @Expose({ name: 'support_5g' })
  support5g: boolean;

  @Expose({ name: 'support_3g' })
  support3g: boolean;

  @Expose({ name: 'support_2g' })
  support2g: boolean;

  @Expose({ name: 'support_hotspot' })
  supportHotspot: boolean;

  @Expose({ name: 'support_roaming' })
  supportRoaming: boolean;

  @Type(() => Number)
  mccmnc: number;
}

export class ProductCoverageModel {
  id: string;
  name: string;

  @Expose({ name: 'code_iso_2' })
  codeIso2: string;

  @Expose({ name: 'code_iso_3' })
  codeIso3: string;

  @Type(() => ProductCoverageNetworkModel)
  networks: ProductCoverageNetworkModel[];
}
