import React from 'react';

interface DeferProps {
  chunkSize: number;
  children: React.ReactNode;
}
// https://itnext.io/improving-slow-mounts-in-react-apps-cff5117696dc
export default function Defer({ children, chunkSize }: DeferProps) {
  const [renderedItemsCount, setRenderedItemsCount] = React.useState(chunkSize);

  const childrenArray = React.useMemo(
    () => React.Children.toArray(children),
    [children],
  );

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (renderedItemsCount > childrenArray.length) {
        clearInterval(interval);
        return;
      }
      setRenderedItemsCount(
        Math.min(renderedItemsCount + chunkSize, childrenArray.length),
      );
    }, 200);

    return () => clearInterval(interval);
  }, [
    renderedItemsCount,
    setRenderedItemsCount,
    childrenArray.length,
    chunkSize,
  ]);

  return <>{childrenArray.slice(0, renderedItemsCount)}</>;
}
