import { Freeze } from 'react-freeze';

interface CloakContainerProps extends React.ComponentProps<'div'> {
  isActive: boolean;
  /** sometimes with freeze the children layout won't showing anything*/
  withFreeze?: boolean;
}

/**
 * when component has unbind all logic about scroll and memoized data has removed
 * this component use for hide the component on layout without unbind
 */
export default function CloakContainer(props: CloakContainerProps) {
  const { isActive, style, withFreeze = true, children, ...rest } = props;

  return (
    <div
      {...rest}
      style={{
        ...style,
        display: isActive ? undefined : 'none',
      }}
    >
      {withFreeze ? (
        <Freeze children={children} freeze={!isActive} />
      ) : (
        children
      )}
    </div>
  );
}
