import { Expose, Type } from 'class-transformer';
import {
  DataPlanTypeEnum,
  ProductItemGradeEnum,
  ProductItemTypeEnum,
  ProductScaleEnum,
} from 'common/constants/enum';

export class ProductItemModel {
  id: string;

  @Expose({ name: 'product_id' })
  productId: string;

  name: string;
  description: string;
  type: ProductItemTypeEnum;

  @Expose({ name: 'is_available' })
  isAvailable: boolean;
  recommended: boolean;

  @Expose({ name: 'sell_currency_code' })
  sellCurrencyCode: string;

  @Expose({ name: 'sell_price' })
  @Type(() => Number)
  sellPrice: number;

  @Expose({ name: 'discounted_price' })
  @Type(() => Number)
  discountedPrice: number;

  @Expose({ name: 'discount_percentage' })
  @Type(() => Number)
  discountPercentage: number | null;

  /** This will be used for sorting the product items */
  @Expose({ name: 'external_name' })
  externalName: string;

  grade: ProductItemGradeEnum;

  @Expose({ name: 'validity_days' })
  validityDays: number;

  @Expose({ name: 'data_type' })
  dataType: DataPlanTypeEnum;

  scale: ProductScaleEnum;

  /** FUP for ``ProductItemDataLimitEnum.Undefined``, GB quota for ``ProductItemDataLimitEnum.Limited`` */
  @Expose({ name: 'quota_in_gb' })
  quotaInGb: number;

  @Expose({ name: 'area_code' })
  areaCode: string;

  @Expose({ name: 'total_countries' })
  @Type(() => Number)
  totalCountries: number;
}
